import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import Head from "next/head";

import React from 'react'

export default function Index() {
  const { t } = useTranslation('common');
  return (
    <>
      <NextSeo
        title={`404 | ${t("headers.not_found")}`}
        description={"page not found"}
      />
      <div className='not-found-page text-light'>
        404 <span className="stick">|</span>
        <h3>
          {`${t("headers.not_found_text")}`}
        </h3>
      </div>
    </>
  )
}


export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}